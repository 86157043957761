import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import Button from '@material-ui/core/Button'

import AltertineLayout from '../layouts/AltertineLayout'

import { getLanguage } from '../layouts/Languages'
import i18next from '../../config/i18n/context'
import Classes from '../../styles/classes'

import PageLink from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log(`hash : ${hash}`)
      Scroll.scroller.scrollTo(hash)
    }
  }

  render() {
    const lang =
      i18next.language ||
      (typeof window !== 'undefined' && window.localStorage.i18nextLng)
    console.log('language home', lang)
    return (
      <StaticQuery
        query={graphql`
          query BlogPageQuery {
            fr: allContentfulArticles(
              filter: { node_locale: { eq: "fr" } }
              sort: { fields: [createdAt], order: ASC }
            ) {
              edges {
                node {
                  id
                  titre
                  createdAt
                  permalink
                  introduction {
                    introduction
                  }
                  image {
                    fixed(width: 500) {
                      ...GatsbyContentfulFixed_noBase64
                    }
                  }
                  auteur {
                    nom
                    prenom
                  }
                }
              }
            }
            en: allContentfulArticles(
              filter: { node_locale: { eq: "en-US" } }
              sort: { fields: [createdAt], order: ASC }
            ) {
              edges {
                node {
                  id
                  titre
                  createdAt
                  permalink
                  introduction {
                    introduction
                  }
                  image {
                    fixed(width: 500) {
                      ...GatsbyContentfulFixed_noBase64
                    }
                  }
                  auteur {
                    nom
                    prenom
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const breadcrumbLinks = [
            <PageLink page={pagesInfos.ComponentBlog}>Blog</PageLink>
          ]
          const Items = data[getLanguage()].edges.map(({ node }) => {
            return (
              <div className="blog-item" key={node.id}>
                <div
                  className="blog-img-1"
                  style={{ background: 'url(' + node.image.fixed.src + ')' }}
                />

                <div className="blog-text">
                  <div className="text-up">
                    <p>{moment(node.createdAt).format('DD/MM/YYYY')}</p>
                    <span>{node.auteur.prenom + ' ' + node.auteur.nom}</span>
                  </div>

                  <div className="blog-text-content">
                    <Link to={node.permalink} title={node.titre}>
                      <h3>{node.titre}</h3>
                    </Link>
                    <p>
                      <ReactMarkdown source={node.introduction.introduction} />
                    </p>

                    <div className="button">
                      <Link to={node.permalink} title={node.titre}>
                        <Button className={css(Classes.buttonPrimary)}>
                          View more
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          return (
            <AltertineLayout
              title="Blog"
              image="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/innobelge/images/bg.jpg"
              breadcrumbLinks={breadcrumbLinks}
            >
              <section className="blog section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="row-left clearfix">{Items}</div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <div className="row-right">
                        <div className="search row-margin">
                          <form action="#">
                            <div className="search">
                              <input
                                className="input-focus"
                                type="search"
                                name="search"
                                placeholder="Search.."
                              />
                              <button type="submit">
                                <i
                                  className="fas fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </form>
                        </div>

                        <div className="row-margin">
                          <h3>About Blog</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into.
                          </p>
                        </div>

                        <div className="catagories row-margin">
                          <h3>Categories</h3>
                          <ul>
                            <li>
                              <a href="#">Technology</a>
                              <span>4</span>
                            </li>
                            <li>
                              <a href="#">Mobile App</a>
                              <span>6</span>
                            </li>
                            <li>
                              <a href="#">Marketing</a>
                              <span>3</span>
                            </li>
                            <li>
                              <a href="#">Web Designer</a>
                              <span>1</span>
                            </li>
                            <li>
                              <a href="#">Graphics Design</a>
                              <span>5</span>
                            </li>
                          </ul>
                        </div>

                        <div className="tags row-margin">
                          <h3>Tags</h3>
                          <ul>
                            <li>
                              <a href="#">Design</a>
                            </li>
                            <li>
                              <a href="#">Mobile</a>
                            </li>
                            <li>
                              <a href="#">CSS3</a>
                            </li>
                            <li>
                              <a href="#">HTML5</a>
                            </li>
                            <li>
                              <a href="#">Creative</a>
                            </li>
                            <li>
                              <a href="#">Web</a>
                            </li>
                          </ul>
                        </div>

                        <div className="meta">
                          <h3>Meta</h3>
                          <ul>
                            <li>
                              <a href="#">Log In</a>
                            </li>
                            <li>
                              <a href="#">Entries RSS</a>
                            </li>
                            <li>
                              <a href="#">Comments RSS</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </AltertineLayout>
          )
        }}
      />
    )
  }
}

export default IndexPage
