import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import shortid from 'shortid'
import Layout from './Layout'
import Menu from './Menu'
import Footer from './Footer'
import ReactMarkdown from 'react-markdown'
import PageLink from '../Link'

import pageInfos from '../../config/i18n/pagesInfos'
import Config from '../../Config'

const AlternativeLayout = ({
  children,
  title,
  subtitle,
  image,
  map,
  contact,
  breadcrumbLinks
}) => {
  const styles = StyleSheet.create({
    header: {
      background: `url(${image}) no-repeat center center`,
      backgroundSize: 'cover'
    },
    overlay: {
      background: 'rgba(0, 35, 55, 0.9)'
    },
    breadcrumbItem: {
      color: '#F6F9FF',
      ':before': {
        color: '#fff',
        content: '\f558',
        fontFamily: 'Font Awesome 5 Free',
        fontSize: '12px'
      }
    },
    breadcrumbActive: {
      fontWeight: 'bold',
      color: Config.colors.secondary
    }
  })

  const BreadcrumbLinks = breadcrumbLinks.map((link, i) => (
    <li
      className={cn(
        'breadcrumb-item',
        css(styles.breadcrumbItem),
        i + 1 === breadcrumbLinks.length && css(styles.breadcrumbActive)
      )}
      key={shortid.generate()}
    >
      {link}
    </li>
  ))
  return (
    <Layout>
      <Menu isDisplayFromHome={false} />
      <header
        className={cn(
          'header-page header-services bg-fade',
          css(styles.header)
        )}
      >
        <div className={cn('overlay', css(styles.overlay))} />

        <div className="container">
          <div className="header-page-content">
            <div className="header-page-text">
              <h1>{title}</h1>
              <p>
                <ReactMarkdown source={subtitle} />
              </p>
            </div>

            <div className="header-breadcrumb">
              <ol className="breadcrumb">
                <li
                  className={cn('breadcrumb-item', css(styles.breadcrumbItem))}
                >
                  <PageLink page={pageInfos.ComponentIndex} title="Home">
                    Home
                  </PageLink>
                </li>
                {BreadcrumbLinks}
              </ol>
            </div>
          </div>
        </div>
      </header>
      {children}
      <Footer map={map} contact={contact} />
    </Layout>
  )
}

AlternativeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  map: PropTypes.bool,
  contact: PropTypes.bool,
  breadcrumbLinks: PropTypes.arrayOf(PropTypes.element)
}

AlternativeLayout.defaultProps = {
  map: false,
  contact: false,
  breadcrumbLinks: []
}

export default AlternativeLayout
